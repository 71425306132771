import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

function ButtonLink(props) {
	if (props.external) {
		switch (props.color) {
			case 'green':
				return (
					<a
						href={props.to}
						className="button-link bg-green"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</a>
				)
			case 'orange':
				return (
					<a
						href={props.to}
						className="button-link bg-orange"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</a>
				)
			case 'pink':
				return (
					<a
						href={props.to}
						className="button-link bg-pink"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</a>
				)
			case 'blue':
				return (
					<a
						href={props.to}
						className="button-link bg-blue"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</a>
				)
			case 'black':
				return (
					<a
						href={props.to}
						className="button-link bg-black"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</a>
				)
			default:
				return (
					<a
						href={props.to}
						className="button-link bg-[#2B95FF]"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</a>
				)
		}
	} else if (props.anchor) {
		switch (props.color) {
			case 'green':
				return (
					<AnchorLink
						to={props.to}
						className="button-link bg-green"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</AnchorLink>
				)
			case 'orange':
				return (
					<AnchorLink
						to={props.to}
						className="button-link bg-orange"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</AnchorLink>
				)
			case 'pink':
				return (
					<AnchorLink
						to={props.to}
						className="button-link bg-pink"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</AnchorLink>
				)
			case 'blue':
				return (
					<AnchorLink
						to={props.to}
						className="button-link bg-blue"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</AnchorLink>
				)
			case 'black':
				return (
					<AnchorLink
						to={props.to}
						className="button-link bg-black"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</AnchorLink>
				)
			default:
				return (
					<AnchorLink
						to={props.to}
						className="button-link bg-[#2B95FF]"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</AnchorLink>
				)
		}
	} else {
		switch (props.color) {
			case 'green':
				return (
					<Link
						to={props.to}
						state={props.state}
						className="button-link bg-green"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</Link>
				)
			case 'orange':
				return (
					<Link
						to={props.to}
						state={props.state}
						className="button-link bg-orange"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</Link>
				)
			case 'pink':
				return (
					<Link
						to={props.to}
						state={props.state}
						className="button-link bg-pink"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</Link>
				)
			case 'blue':
				return (
					<Link
						to={props.to}
						state={props.state}
						className="button-link bg-blue"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</Link>
				)
			case 'black':
				return (
					<Link
						to={props.to}
						state={props.state}
						className="button-link bg-black"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</Link>
				)
			default:
				return (
					<Link
						to={props.to}
						state={props.state}
						className="button-link bg-blue"
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.children}
					</Link>
				)
		}
	}
}

export default ButtonLink
