import { Link } from 'gatsby'
import React from 'react'
import { useLocation } from '@reach/router'

import DiscordImage from '../images/icons/classify discord.webp'
import TwitterImage from '../images/icons/classify twitter.webp'
import InstagramImage from '../images/icons/classify instagram.webp'
import YoutubeImage from '../images/icons/classify youtube.webp'

const icons = [
	{
		name: 'Discord',
		url: 'https://discord.gg/EYSZ5QEEYC',
		image: DiscordImage,
	},
	{
		name: 'Twitter',
		url: 'https://twitter.com/classifyapp',
		image: TwitterImage,
	},
	{
		name: 'Instagram',
		url: 'https://www.instagram.com/classifyapp',
		image: InstagramImage,
	},
	{
		name: 'Youtube',
		url: 'https://www.youtube.com/channel/UC0gSikZD5N-Uoej3lPuknxw',
		image: YoutubeImage,
	},
]

function Footer(props) {
	const { pathname: path } = useLocation()

	return (
		<footer
			className="text-black-alt text-sm px-12"
			style={{ borderTop: 'solid 1px #F5F5F5' }}
		>
			<div className="hidden md:block items-center py-3">
				{/* <div className="text-xs">© 2023 Classify</div> */}
				{path !== '/' ? (
					<ul className="flex justify-center opacity-30">
						{icons.map(({ name, url, image }, index) => (
							<li className="mx-3" key={index}>
								<a
									href={url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={image}
										alt={name}
										width="16"
										height="16"
										className="w-5 h-auto"
									/>
								</a>
							</li>
						))}
					</ul>
				) : (
					<ul className="flex justify-center">
						<li className="mx-2">
							<Link to="/legal">Legal</Link>
						</li>
						<li className="mx-2">
							<Link to="/blog">Blog</Link>
						</li>
						<li className="mx-2">
							<Link to="/support/contact">Contact Us</Link>
						</li>
					</ul>
				)}
			</div>

			<div className="md:hidden items-center py-3">
				{path !== '/' ? (
					<ul className="flex justify-center opacity-30">
						{icons.map(({ name, url, image }, index) => (
							<li className="mx-3" key={-index}>
								<a
									href={url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={image}
										alt={name}
										width="16"
										height="16"
										className="w-6 h-auto"
									/>
								</a>
							</li>
						))}
					</ul>
				) : (
					<ul className="flex justify-center">
						<li className="mx-2">
							<Link to="/legal">Legal</Link>
						</li>
						<li className="mx-2">
							<Link to="/blog">Blog</Link>
						</li>
						<li className="mx-2">
							<Link to="/support/contact">Contact Us</Link>
						</li>
					</ul>
				)}
			</div>
		</footer>
	)
}

export default Footer
