import { Link } from 'gatsby'
import React, { useState } from 'react'
import Container from '../Container'
import Logo from '../../images/icons/logo_black.webp'
import LogoWhite from '../../images/icons/logo_white.webp'
import Hamburger from '../../images/icons/hamburger.webp'
import HamburgerLight from '../../images/icons/hamburger_light.webp'
import CloseBtnLight from '../../images/icons/close-icon-light.webp'
import CloseBtn from '../../images/icons/close-icon.webp'
import StoreButtons from '../StoreButtons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import gplay from '../../images/icons/google-play.webp'
import appstore from '../../images/icons/app-store.webp'
import AlertConfig from '../../ALERTBAR_CONFIG'
import ButtonLink from '../ButtonLink'

function Navbar({ dark, navy }) {
	const [mobileNav, setMobileNav] = useState(false)

	const handleClick = () => {
		setMobileNav(!mobileNav)
	}

	let variant
	if (dark) {
		variant = 'bg-dark-alt'
	} else if (navy) {
		variant = 'bg-navy'
	} else {
		variant = 'bg-white'
	}

	return (
		<nav
			className={`py-2 fixed z-50 left-0 right-0 flex items-center ${variant}`}
			style={{
				top: AlertConfig.enabled ? '40px' : 0,
				height: '60px',
				borderBottom:
					dark || navy ? 'solid 1px #282828' : 'solid 1px #F5F5F5',
			}}
		>
			<div className="hidden lg:block w-full">
				<div className="mx-auto container px-4 md:px-12 2xl:px-32">
					<div className="flex justify-between items-center z-10">
						<div className="xl:w-[300px]">
							<Link
								to="/"
								className={
									'inline-flex items-center text-2xl font-valorant ' +
									(dark || navy ? 'text-white' : 'text-black')
								}
							>
								<img
									src={dark || navy ? LogoWhite : Logo}
									alt="Logo"
									width="2000"
									height="1576"
									className="w-auto h-5 mr-2"
								/>
								<span className="pt-1.5">Classify</span>
							</Link>
						</div>
						<ul
							className={
								'flex ' +
								(dark || navy ? 'nav-links-dark' : 'nav-links')
							}
						>
							<li className="mx-3">
								<Link
									to="/mission"
									activeClassName={
										dark || navy
											? 'text-white'
											: 'text-black'
									}
								>
									Mission
								</Link>
							</li>
							<li className="mx-3">
								<Link
									to="/blog"
									activeClassName={
										dark || navy
											? 'text-white'
											: 'text-black'
									}
								>
									Blog
								</Link>
							</li>
							<li className="mx-3">
								<Link
									to="/support"
									activeClassName={
										dark || navy
											? 'text-white'
											: 'text-black'
									}
								>
									Support
								</Link>
							</li>
							<li className="mx-3">
								<Link
									to="/ambassador"
									activeClassName={
										dark || navy
											? 'text-white'
											: 'text-black'
									}
								>
									Ambassador
								</Link>
							</li>
							<li className="mx-3">
								<Link
									to="/blog/updates"
									activeClassName={
										dark || navy
											? 'text-white'
											: 'text-black'
									}
								>
									Updates
								</Link>
							</li>
							<li className="mx-3">
								<Link
									to="/about"
									activeClassName={
										dark || navy
											? 'text-white'
											: 'text-black'
									}
								>
									About
								</Link>
							</li>
						</ul>
						<div className="flex justify-end">
							<StoreButtons />
						</div>
					</div>
				</div>
			</div>
			<div className="block lg:hidden w-full">
				<div className="flex justify-center px-4 relative">
					<div className="lg:hidden flex absolute left-4 top-0 items-center pt-[6px] w-[110px]">
						<button onClick={handleClick}>
							<img
								src={
									mobileNav
										? dark || navy
											? CloseBtnLight
											: CloseBtn
										: dark || navy
										? HamburgerLight
										: Hamburger
								}
								alt="Mobile nav icon"
								width="512"
								height="512"
								className="h-7 w-auto"
							/>
						</button>
					</div>
					<div className="flex justify-center">
						<Link
							to="/"
							className={
								'inline-flex items-center text-2xl font-valorant ' +
								(dark || navy ? 'text-white' : 'text-black')
							}
						>
							<img
								src={dark || navy ? LogoWhite : Logo}
								alt="Logo"
								width="2000"
								height="1576"
								className="w-auto h-5 mr-2"
							/>
							<span className="pt-1.5">Classify</span>
						</Link>
					</div>
					<div
						className="lg:hidden flex absolute right-4 top-0 items-center px-4 w-[130px]"
						id="nav-store-btn"
					>
						<ButtonLink to="/#hero" anchor>
							Download
						</ButtonLink>
					</div>
				</div>
			</div>

			{mobileNav && (
				<div
					className="fixed inset-0 flex lg:hidden flex-col justify-around items-center py-12"
					style={{
						top: AlertConfig.enabled ? '100px' : '60px',
						background: 'linear-gradient(#ffffff, #FFBEBE)',
						borderTop: 'solid 1px #F5F5F5',
					}}
				>
					<ul
						onClick={() => setMobileNav(false)}
						className={
							'flex flex-col items-center text-black font-bold text-2xl uppercase'
						}
					>
						<li className="pb-4">
							<Link to="/mission" activeClassName="text-black">
								Mission
							</Link>
						</li>
						<li className="pb-4">
							<Link to="/blog" activeClassName="text-black">
								Blog
							</Link>
						</li>
						<li className="pb-4">
							<Link to="/support" activeClassName="text-black">
								Support
							</Link>
						</li>
						<li className="pb-4">
							<Link to="/ambassador" activeClassName="text-black">
								Ambassador
							</Link>
						</li>
						<li className="pb-4">
							<Link
								to="/blog/updates"
								activeClassName="text-black"
							>
								Updates
							</Link>
						</li>
						<li className="pb-4">
							<Link to="/about" activeClassName="text-black">
								About
							</Link>
						</li>
					</ul>
					<div className="flex flex-col text-lg text-white font-regular">
						<a
							className="bg-blue px-5 py-2 rounded-full mb-6 flex flex-nowrap whitespace-nowrap items-center"
							href="https://apps.apple.com/gb/app/classify-academic-companion/id1463780331"
						>
							<img
								src={appstore}
								alt="Apple App Store button"
								width="16"
								height="16"
								className="w-auto h-6 mr-2"
							/>
							App store
						</a>
						<span className="mx-2" />
						<a
							className="bg-blue px-5 py-2 rounded-full mb-6 flex flex-nowrap whitespace-nowrap items-center"
							href="https://play.google.com/store/apps/details?id=com.principal.classify"
						>
							<img
								src={gplay}
								alt="Google Play Store button"
								width="16"
								height="16"
								className="w-auto h-6 mr-2"
							/>
							Play store
						</a>
					</div>
				</div>
			)}
		</nav>
	)
}

export default Navbar
