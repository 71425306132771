import React from 'react'

function Container(props) {
	if (props['no-vertical']) {
		return (
			<div
				className={
					'mx-auto relative px-4 md:px-12 xl:w-4/5 2xl:w-3/5 ' +
						props.className || ''
				}
				style={{ maxWidth: '1536px' }}
			>
				{props.children}
			</div>
		)
	} else {
		return (
			<div
				className={
					'mx-auto relative px-4 md:px-12 xl:w-4/5 2xl:w-3/5 py-36 ' +
						props.className || ''
				}
				style={{ maxWidth: '1536px' }}
			>
				{props.children}
			</div>
		)
	}
}

export default Container
