/**
 * Alert bar config file
 */

const config = {
	//Sets the bar to be enabled or disabled.
	enabled: false,

	//Sets the message for the Alert Bar - this can use HTML so that you can insert a link.
	message:
		'Version 2 is now live! Make sure to check the new update out and also upvote the app on <a href="https://www.producthunt.com/@classifyapp" target="_blank" rel="noopener noreferrer">Product Hunt</a>. 💈',

	//Adds a link globally to the product bar - makes the whole thing clickable.
	//To disable set it to false, ie: `link: false`
	link: 'https://www.producthunt.com/@classifyapp',
}

export default config
