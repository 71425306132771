import React from 'react';
import AlertConfig from '../ALERTBAR_CONFIG'

function AlertBar(props) {
  if (AlertConfig.link) return (
    <a href={AlertConfig.link} target="_blank" rel="noopener noreferrer" className="alert-bar">
      <div dangerouslySetInnerHTML={{__html: AlertConfig.message}} />
    </a>
  )
  return (
    <div className="alert-bar">
      <div dangerouslySetInnerHTML={{__html: AlertConfig.message}} />  
    </div>
  );
}

export default AlertBar;