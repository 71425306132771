import React from 'react'
import ButtonLink from '../components/ButtonLink'
import gplay from '../images/icons/google-play.webp'
import appstore from '../images/icons/app-store.webp'

function StoreButtons(props) {
	return (
		<>
			<div
				className={`flex text-sm min-w-[300px] ${
					props.large ? 'scale-125' : ''
				}`}
			>
				<ButtonLink
					external
					to="https://apps.apple.com/gb/app/classify-academic-companion/id1463780331"
					color="blue"
				>
					<span className="flex items-center">
						<img
							src={appstore}
							alt="Apple App store button"
							width="16"
							height="16"
							className="w-auto h-5 mr-2"
						/>
						App store
					</span>
				</ButtonLink>
				<span className="mx-2" />
				<ButtonLink
					external
					to="https://play.google.com/store/apps/details?id=com.principal.classify"
					color="blue"
				>
					<span className="flex items-center">
						<img
							src={gplay}
							alt="Google Play Store button"
							width="16"
							height="16"
							className="w-auto h-5 mr-2"
						/>
						Play store
					</span>
				</ButtonLink>
			</div>
		</>
	)
}

export default StoreButtons
